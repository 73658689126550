import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../common/providers/user.provider";
import Button from "../../components/button.component";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import FooterDashboard from "../../components/footer-dashboard.component";
import InputField from "../../components/input-field.component";
import { SavedSearchType } from "../../common/enums/saved-search-types.enum";
import {
  createSaveSearchRecord,
  deleteSaveSearchRecord,
  getSaveSearchRecords,
} from "../../services/saved-search-api.service";
import moment from "moment";
import { getAllLocationsTypeList } from "../../services/types-api.service";
import Select from "react-select";
import KeywordInputInputNormalMandatory from "../../components/keyword-input-normal-mandatory.component";
import SimpleKeywordInput from "../../components/keyword-input-simple.component";

interface LocationOption {
  value: string;
  label: string;
}

export default function Search() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token } = useToken();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationsTypeList, setLocationsTypeList] = useState<LocationOption[]>(
    []
  );

  // Keywords filtering states
  const [keywords, setKeywords] = useState<string[]>([]);
  const [mandatoryKeywords, setMandatoryKeywords] = useState<string[]>([]);
  const [excludeKeywords, setExcludeKeywords] = useState<string[]>([]);
  const [isShowExcludeKeywords, setIsShowExcludeKeywords] = useState(false);

  // Locations filtering states
  const [locations, setLocations] = useState("");
  // Experience filtering states
  const [minExperience, setMinExperience] = useState("");
  const [maxExperience, setMaxExperience] = useState("");
  // Salary filtering states
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  // Company filtering states
  const [company, setCompany] = useState("");
  const [companySearchOption, setCompanySearchOption] = useState(
    "currentCompanyNames"
  );
  // Designation filtering states
  const [designation, setDesignation] = useState("");
  const [designationSearchOption, setDesignationSearchOption] = useState(
    "currentDesignationNames"
  );

  // Education filtering states (UG Qualification)
  const [selectedUGQualification, setSelectedUGQualification] = useState("");
  const [specificUGDegree, setSpecificUGDegree] = useState("");
  const [specificUGInstitution, setSpecificUGInstitution] = useState("");

  // Education filtering states (PG Qualification)
  const [selectedPGQualification, setSelectedPGQualification] = useState("");
  const [specificPGDegree, setSpecificPGDegree] = useState("");
  const [specificPGInstitution, setSpecificPGInstitution] = useState("");

  // Education filtering states (PPG Qualification)
  const [selectedPPGQualification, setSelectedPPGQualification] = useState("");
  const [specificPPGDegree, setSpecificPPGDegree] = useState("");
  const [specificPPGInstitution, setSpecificPPGInstitution] = useState("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");
    if (accessToken && (user || storedUser)) {
      handleGetAllLocationsTypeList(accessToken);
    }
  }, [user]);

  // Handling the select options
  const handleSelectChange = (selectedOption: any) => {
    setLocations(selectedOption ? selectedOption.label : "");
  };

  const handleGetAllLocationsTypeList = async (token: any) => {
    try {
      setIsLoading(true);
      // Calling to the API service
      const response = await getAllLocationsTypeList(token);
      // Handling the response
      if (response.success) {
        const formattedLocations = response.data.data.map((company: any) => ({
          value: company._id,
          label: company.name,
        }));
        setLocationsTypeList(formattedLocations);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    const params: any = {};

    params.page = 1;
    params.pageSize = 5;

    // Adding all keyword filters to params
    if (keywords && keywords.length > 0) {
      params.keywords = keywords.join(",");
    }
    if (mandatoryKeywords && mandatoryKeywords.length > 0) {
      params.mandatoryKeywords = mandatoryKeywords.join(",");
    }
    if (excludeKeywords && excludeKeywords.length > 0) {
      params.excludeKeywords = excludeKeywords.join(",");
    }

    // Other filter parameters
    if (locations) params.locations = locations;
    if (minExperience) params.minExperience = minExperience;
    if (maxExperience) params.maxExperience = maxExperience;
    if (minSalary) params.minSalary = minSalary;
    if (maxSalary) params.maxSalary = maxSalary;

    // Company and Designation
    if (company) params[companySearchOption] = company;
    if (designation) params[designationSearchOption] = designation;

    // Education Qualification Filters

    // Logic for UG qualifications...
    if (selectedUGQualification === "Any UG qualification") {
      params.anyUGQualification = true;
    } else if (selectedUGQualification === "No UG qualification") {
      params.noUGQualification = true;
    } else if (selectedUGQualification === "Specific UG qualification") {
      if (specificUGDegree) params.specificUGDegree = specificUGDegree;
      if (specificUGInstitution)
        params.specificUGInstitution = specificUGInstitution;
    }

    // Logic for PG qualifications...
    if (selectedPGQualification === "Any PG qualification") {
      params.anyPGQualification = true;
    } else if (selectedPGQualification === "No PG qualification") {
      params.noPGQualification = true;
    } else if (selectedPGQualification === "Specific PG qualification") {
      if (specificPGDegree) params.specificPGDegree = specificPGDegree;
      if (specificPGInstitution)
        params.specificPGInstitution = specificPGInstitution;
    }

    // Logic for PPG qualifications...
    if (selectedPPGQualification === "Any PPG qualification") {
      params.anyPPGQualification = true;
    } else if (selectedPPGQualification === "No PPG qualification") {
      params.noPPGQualification = true;
    } else if (selectedPPGQualification === "Specific PPG qualification") {
      if (specificPPGDegree) params.specificPPGDegree = specificPPGDegree;
      if (specificPPGInstitution)
        params.specificPPGInstitution = specificPPGInstitution;
    }

    const searchParams = new URLSearchParams();
    for (const key in params) {
      if (params[key] !== undefined && params[key] !== "") {
        searchParams.append(key, params[key].toString());
      }
    }

    // Check if any filter (except pagination) is set
    const hasFilters = Object.keys(params).some(
      (key) =>
        key !== "page" &&
        key !== "pageSize" &&
        params[key] !== undefined &&
        params[key] !== ""
    );
    // Only save the recent search record if filters are present
    if (hasFilters) {
      const searchParams = new URLSearchParams();
      for (const key in params) {
        if (params[key] !== undefined && params[key] !== "") {
          searchParams.append(key, params[key].toString());
        }
      }
      handleRecentSearchSavedRecord(`?${searchParams.toString()}`);
    }

    // console.log("searchParams : ", searchParams.toString());
    navigate(`/user/results?${searchParams.toString()}`);
  };

  /*===============================================================================
    ============================SAVE SEARCH GOES HERE===============================
    ================================================================================*/

  const [savedSearchData, setSavedSearchData] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      handleGetAllSavedSearchRecord(accessToken);
    }
  }, []);

  const handleGetAllSavedSearchRecord = async (token: string | any) => {
    try {
      setIsLoading(true);
      const search_type = SavedSearchType.SAVED_SEARCH;

      const response = await getSaveSearchRecords(token, search_type);
      if (response.success) {
        setSavedSearchData(response?.data?.data);
        console.log("response?.data?.data : ", response?.data?.data);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleSearchClick = (searchPrams: string) => {
    navigate(`/user/results${searchPrams}`);
  };

  /*===============================================================================
    ============================RECENT SEARCH GOES HERE===============================
    ================================================================================*/

  const [recentSearchData, setRecentSearchData] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      handleGetAllRecentSearchRecord(accessToken);
    }
  }, []);

  const handleGetAllRecentSearchRecord = async (token: string | any) => {
    try {
      setIsLoading(true);
      const search_type = SavedSearchType.RECENT_SEARCH;

      const response = await getSaveSearchRecords(token, search_type);
      if (response.success) {
        setRecentSearchData(response?.data?.data);
        console.log("response?.data?.data : ", response?.data?.data);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const handleRecentSearchClick = (searchPrams: string) => {
    navigate(`/user/results${searchPrams}`);
  };

  const handleRecentSearchSavedRecord = async (queryString: string) => {
    try {
      setIsLoading(true);
      const saved_type = SavedSearchType.RECENT_SEARCH;

      const search_params = queryString;

      const search_name = search_params;

      const response = await createSaveSearchRecord(
        token,
        saved_type,
        search_params,
        search_name
      );
      if (response.success) {
        console.log(response.data?.message);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  const removeQueryParams = (
    paramsString: string,
    paramsToRemove: string[]
  ) => {
    // Remove the initial "?" if it exists
    const cleanedString = paramsString.startsWith("?")
      ? paramsString.slice(1)
      : paramsString;

    // Convert the params string into an object
    const params = new URLSearchParams(cleanedString);

    // Remove specified parameters
    paramsToRemove.forEach((param) => params.delete(param));

    // Reconstruct the query string with the remaining parameters
    const updatedParamsString = params.toString();

    // If there are remaining parameters, return them without the "?", otherwise return an empty string
    return updatedParamsString || "";
  };

  const handleDeleteSearchRecord = async (recordId: string) => {
    try {
      setIsLoading(true);

      const response = await deleteSaveSearchRecord(token, recordId);
      if (response.success) {
        handleGetAllRecentSearchRecord(token);
        handleGetAllSavedSearchRecord(token);
        toast.success(response?.data?.message);
      } else {
        toast.error(response.error);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error during form action:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("Keywords : ", keywords);
  }, [keywords]);

  useEffect(() => {
    console.log("Mandatory Keywords : ", mandatoryKeywords);
  }, [mandatoryKeywords]);

  useEffect(() => {
    console.log("Exclude Keywords : ", excludeKeywords);
  }, [excludeKeywords]);

  // const excludeKeywordsUseMemeo = useMemo(() => excludeKeywords, []);

  return (
    <React.Fragment>
      <div className="min-h-screen w-full flex flex-col lg:flex-row lg:px-24 lg:pt-16 mx-auto overflow-auto">
        {/* Left side component */}
        <div className="lg:w-2/3 w-full bg-white rounded-xl mx-4 p-12 shadow-custom mb-8 lg:mb-36">
          <h1 className="text-2xl text-slate-700 font-bold">
            Search Candidates
          </h1>

          <div className="pb-20 relative">
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              {/* Keywords Filter */}
              <div className="flex justify-between items-center cursor-pointer">
                <h1 className="text-lg font-bold text-slate-700">Keywords</h1>
              </div>
              <>
                <div className="w-full flex flex-row justify-between items-center">
                  <KeywordInputInputNormalMandatory
                    initialKeywords={keywords}
                    initialMandatoryKeywords={mandatoryKeywords}
                    onUpdateKeywords={(
                      normal: string[],
                      mandatory: string[]
                    ) => {
                      setKeywords(normal);
                      setMandatoryKeywords(mandatory);
                    }}
                  />
                </div>
                <div className="w-full flex flex-row justify-end items-center mt-5">
                  {/* <p>
                    <input
                      type="checkbox"
                      id="mandatoryKeywords"
                      name="mandatoryKeywords"
                      className="cursor-pointer mr-2"
                      checked={mandatoryKeywords}
                      onChange={(e) => setMandatoryKeywords(e.target.checked)}
                    />
                    Mark all keywords as mandatory
                  </p> */}
                  <p>
                    Search keyword in
                    <select
                      id="searchOption"
                      name="searchOption"
                      className="ml-2"
                    >
                      <option value="resumeTitle">Resume Title</option>
                      <option value="resumeTitleAndKeywords">
                        Resume Title and Keywords
                      </option>
                      <option value="resumeSynopsis">Resume Synopsis</option>
                      <option value="entireResume">Entire Resume</option>
                    </select>
                  </p>
                </div>
                <p
                  className="text-sm underline text-blue-800 cursor-pointer font-semibold my-5 w-fit"
                  onClick={() =>
                    setIsShowExcludeKeywords(!isShowExcludeKeywords)
                  }
                >
                  + Add Exclude Keywords
                </p>
                {isShowExcludeKeywords && (
                  <SimpleKeywordInput
                    initialKeywords={excludeKeywords}
                    onUpdateKeywords={(updatedKeywords) =>
                      setExcludeKeywords(updatedKeywords)
                    }
                  />
                )}
              </>
            </div>

            {/* Experience Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer mb-5">
                <h1 className="text-lg font-bold text-slate-700">Experience</h1>
              </div>
              <div className="flex items-center space-x-2 w-1/2">
                <InputField
                  icon="fa-solid fa-magnifying-glass"
                  type="text"
                  placeholder="Min Experience"
                  value={minExperience}
                  onChange={(e: any) => setMinExperience(e.target.value)}
                />
                <span>to</span>
                <InputField
                  icon="fa-solid fa-magnifying-glass"
                  type="text"
                  placeholder="Max Experience"
                  value={maxExperience}
                  onChange={(e: any) => setMaxExperience(e.target.value)}
                />
                <span>Year</span>
              </div>
            </div>

            {/* Locations Filter */}
            {/* <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer">
                <h1 className="text-lg font-bold text-slate-700">
                  Location of Candidate
                </h1>
              </div>
              <>
                <InputField
                  icon="fa-solid fa-magnifying-glass"
                  type="text"
                  placeholder="Add location"
                  value={locations}
                  onChange={(e: any) => setLocations(e.target.value)}
                />
              </>
            </div> */}

            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer">
                <h1 className="text-lg font-bold text-slate-700">
                  Location of Candidate
                </h1>
              </div>
              <>
                <Select
                  className="basic-single mt-2"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  isSearchable={true}
                  name="location"
                  options={locationsTypeList}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid #cbd5e1", // Matches the border color of the old component
                      borderRadius: "0.375rem", // Slight rounding for consistent design
                      padding: "0.25rem", // Padding similar to InputField
                      boxShadow: "none",
                      "&:hover": { borderColor: "#a0aec0" }, // Subtle hover effect
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0.25rem 0.5rem", // Inner padding to match the text input
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: "1rem", // Match font size
                      color: "#4a5568", // Text color for consistency
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#a0aec0", // Placeholder color
                    }),
                    menu: (provided) => ({
                      ...provided,
                      borderRadius: "0.375rem", // Match dropdown style
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for dropdown
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#e2e8f0"
                        : state.isFocused
                        ? "#edf2f7"
                        : "white",
                      color: "#2d3748", // Text color in options
                      cursor: "pointer",
                    }),
                  }}
                />
              </>
            </div>

            {/* Salary Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer mb-5">
                <h1 className="text-lg font-bold text-slate-700">Salary</h1>
              </div>
              <div className="flex items-center space-x-2 w-1/2">
                <InputField
                  icon="fa-solid fa-magnifying-glass"
                  type="text"
                  placeholder="Min Salary"
                  value={minSalary}
                  onChange={(e: any) => setMinSalary(e.target.value)}
                />
                <span>to</span>
                <InputField
                  icon="fa-solid fa-magnifying-glass"
                  type="text"
                  placeholder="Max Salary"
                  value={maxSalary}
                  onChange={(e: any) => setMaxSalary(e.target.value)}
                />
              </div>
            </div>

            {/* Company Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer">
                <h1 className="text-lg font-bold text-slate-700">Company</h1>
              </div>
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Search company"
                value={company}
                onChange={(e: any) => setCompany(e.target.value)}
              />
              <p>
                Search company in
                <select
                  id="companySearchOption"
                  name="companySearchOption"
                  className="ml-2"
                  value={companySearchOption}
                  onChange={(e) => setCompanySearchOption(e.target.value)}
                >
                  <option value="currentCompanyNames">Current Company</option>
                  <option value="previousCompanyNames">Previous Company</option>
                  <option value="companyNames">
                    Current and Previous Company
                  </option>
                </select>
              </p>
            </div>

            {/* Designation Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer">
                <h1 className="text-lg font-bold text-slate-700">
                  Designation
                </h1>
              </div>
              <InputField
                icon="fa-solid fa-magnifying-glass"
                type="text"
                placeholder="Search designation"
                value={designation}
                onChange={(e: any) => setDesignation(e.target.value)}
              />
              <p>
                Search designation in
                <select
                  id="designationSearchOption"
                  name="designationSearchOption"
                  className="ml-2"
                  value={designationSearchOption}
                  onChange={(e) => setDesignationSearchOption(e.target.value)}
                >
                  <option value="currentDesignationNames">
                    Current Designation
                  </option>
                  <option value="previousDesignationNames">
                    Previous Designation
                  </option>
                  <option value="designationNames">
                    Current and Previous Designation
                  </option>
                </select>
              </p>
            </div>

            {/* Education Filter */}
            <div className="my-10 pt-2 pb-5 border-b-2 border-slate-300">
              <div className="flex justify-between items-center cursor-pointer">
                <h1 className="text-lg font-bold text-slate-700">Education</h1>
              </div>

              {/* UG Qualification Education Filter */}
              <div className="p-4 max-w-full mx-auto">
                <h2 className="text-sm font-semibold mb-4">UG Qualification</h2>
                <div className="flex gap-4 mb-4">
                  <button
                    onClick={() =>
                      setSelectedUGQualification("Any UG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedUGQualification === "Any UG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    Any UG qualification
                  </button>
                  <button
                    onClick={() =>
                      setSelectedUGQualification("Specific UG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedUGQualification === "Specific UG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    Specific UG qualification
                  </button>
                  <button
                    onClick={() =>
                      setSelectedUGQualification("No UG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedUGQualification === "No UG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    No UG qualification
                  </button>
                </div>
                <div className="text-sm text-slate-500 mb-4">
                  {selectedUGQualification === "Any UG qualification" && (
                    <p>
                      Any UG - Candidates with any UG qualification will appear
                      in the result.
                    </p>
                  )}
                  {selectedUGQualification === "No UG qualification" && (
                    <p>
                      No UG qualification - Candidates who have only completed
                      10th or 12th but are not pursuing/ have pursued graduation
                      will appear in the result.
                    </p>
                  )}
                </div>
                {selectedUGQualification === "Specific UG qualification" && (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-slate-700 text-sm font-medium mb-1">
                        Choose Course
                      </label>
                      <input
                        type="text"
                        placeholder="Type or select UG course from list"
                        value={specificUGDegree}
                        onChange={(e) => setSpecificUGDegree(e.target.value)}
                        className="w-full p-3 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-slate-700 text-sm font-medium mb-1">
                        Institute
                      </label>
                      <input
                        type="text"
                        placeholder="Select institute"
                        value={specificUGInstitution}
                        onChange={(e) =>
                          setSpecificUGInstitution(e.target.value)
                        }
                        className="w-full p-3 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* PG Qualification Education Filter */}
              <div className="p-4 max-w-full mx-auto">
                <h2 className="text-sm font-semibold mb-4">PG Qualification</h2>
                <div className="flex gap-4 mb-4">
                  <button
                    onClick={() =>
                      setSelectedPGQualification("Any PG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedPGQualification === "Any PG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    Any PG qualification
                  </button>
                  <button
                    onClick={() =>
                      setSelectedPGQualification("Specific PG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedPGQualification === "Specific PG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    Specific PG qualification
                  </button>
                  <button
                    onClick={() =>
                      setSelectedPGQualification("No PG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedPGQualification === "No PG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    No PG qualification
                  </button>
                </div>
                <div className="text-sm text-slate-500 mb-4">
                  {selectedPGQualification === "Any PG qualification" && (
                    <p>
                      Any PG - Candidates with any PG qualification will appear
                      in the result.
                    </p>
                  )}
                  {selectedPGQualification === "No PG qualification" && (
                    <p>
                      No PG qualification - Candidates who have completed UG but
                      are not pursuing/have pursued PG will appear in the result
                    </p>
                  )}
                </div>
                {selectedPGQualification === "Specific PG qualification" && (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-slate-700 text-sm font-medium mb-1">
                        Choose Course
                      </label>
                      <input
                        type="text"
                        placeholder="Type or select UG course from list"
                        value={specificPGDegree}
                        onChange={(e) => setSpecificPGDegree(e.target.value)}
                        className="w-full p-3 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-slate-700 text-sm font-medium mb-1">
                        Institute
                      </label>
                      <input
                        type="text"
                        placeholder="Select institute"
                        value={specificPGInstitution}
                        onChange={(e) =>
                          setSpecificPGInstitution(e.target.value)
                        }
                        className="w-full p-3 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                )}
              </div>

              {/* PPG Qualification Education Filter */}
              <div className="p-4 max-w-full mx-auto">
                <h2 className="text-sm font-semibold mb-4">
                  PPG Qualification
                </h2>

                <div className="flex gap-4 mb-4">
                  <button
                    onClick={() =>
                      setSelectedPPGQualification("Any PPG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedPPGQualification === "Any PPG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    Any PPG qualification
                  </button>
                  <button
                    onClick={() =>
                      setSelectedPPGQualification("Specific PPG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedPPGQualification === "Specific PPG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    Specific PPG qualification
                  </button>
                  <button
                    onClick={() =>
                      setSelectedPPGQualification("No PPG qualification")
                    }
                    className={`px-4 py-2 border rounded-full text-sm font-medium w-full ${
                      selectedPPGQualification === "No PPG qualification"
                        ? "bg-blue-100 border-blue-400 text-blue-700"
                        : "bg-white border-slate-300 text-slate-700"
                    }`}
                  >
                    No PPG qualification
                  </button>
                </div>
                <div className="text-sm text-slate-500 mb-4">
                  {selectedPPGQualification === "Any PPG qualification" && (
                    <p>
                      Any PPG - Candidates with any PPG qualification will
                      appear in the result.
                    </p>
                  )}
                  {selectedPPGQualification === "No PPG qualification" && (
                    <p>
                      No PPG qualification - Candidates who have completed PG
                      but are not pursuing/have pursued PPG will appear in the
                      result
                    </p>
                  )}
                </div>
                {selectedPPGQualification === "Specific PPG qualification" && (
                  <div className="space-y-4">
                    <div>
                      <label className="block text-slate-700 text-sm font-medium mb-1">
                        Choose Course
                      </label>
                      <input
                        type="text"
                        placeholder="Type or select PPG course from list"
                        value={specificPPGDegree}
                        onChange={(e) => setSpecificPPGDegree(e.target.value)}
                        className="w-full p-3 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-slate-700 text-sm font-medium mb-1">
                        Institute
                      </label>
                      <input
                        type="text"
                        placeholder="Select institute"
                        value={specificPPGInstitution}
                        onChange={(e) =>
                          setSpecificPPGInstitution(e.target.value)
                        }
                        className="w-full p-3 border border-slate-300 rounded-md shadow-sm text-slate-700 focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Actions */}
            <div className="fixed bottom-0 left-0 w-2/3 bg-white py-4 px-12 border-t border-gray-300 shadow-md flex flex-row space-x-6 justify-end items-center mx-4 rounded-tl-xl rounded-tr-xl">
              <div className="w-1/3 flex flex-row justify-center items-center space-x-10">
                <button
                  className={`w-full h-14 bg-gray-200 hover:bg-gray-300 text-gray-700 text-sm font-bold py-2 px-4 rounded-2xl transition duration-300`}
                  type="button"
                  onClick={() => {
                    setKeywords([]);
                    setMandatoryKeywords([]);
                    setExcludeKeywords([]);
                    setLocations("");
                    setMinExperience("");
                    setMaxExperience("");
                    setMinSalary("");
                    setMaxSalary("");
                    setCompany("");
                    setCompanySearchOption("currentCompany");
                    setDesignation("");
                    setDesignationSearchOption("currentDesignation");
                    setSelectedUGQualification("");
                    setSpecificUGDegree("");
                    setSpecificUGInstitution("");
                    setSelectedPGQualification("");
                    setSpecificPGDegree("");
                    setSpecificPGInstitution("");
                    setSelectedPPGQualification("");
                    setSpecificPPGDegree("");
                    setSpecificPPGInstitution("");
                  }}
                >
                  Reset Filters
                </button>
                <Button
                  name="Search"
                  handleAction={handleSearch}
                  icon={"fa-solid fa-arrow-right"}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Right side component */}
        <div className="lg:w-1/3 w-full bg-slate-50 rounded-lg p-10 mx-4 lg:mx-16 mb-8 lg:mb-36">
          {/* Recent Searches */}
          <div className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <span className="mr-2">🔄</span> Recent Searches
            </h2>
            {recentSearchData.length > 0 ? (
              recentSearchData.map((search: any) => (
                <div key={search._id} className="my-6">
                  <p className="text-gray-700 mb-1 text-sm">
                    {removeQueryParams(search.search_name, [
                      "page",
                      "pageSize",
                    ])}
                  </p>
                  <div className=" flex items-center justify-start space-x-4">
                    {/* <button
                      onClick={() =>
                        handleRecentSearchClick(search.searchPrams)
                      }
                      className="text-blue-600 text-sm hover:underline"
                    >
                      Fill this search
                    </button> */}
                    <button
                      onClick={() =>
                        handleRecentSearchClick(search.searchPrams)
                      }
                      className="text-blue-600 text-sm hover:underline"
                    >
                      Search profiles
                    </button>{" "}
                    <p className="ml-4">|</p>
                    <i
                      className="fa-regular fa-trash-can cursor-pointer text-blue-600 hover:text-blue-700 ml-4"
                      onClick={() => handleDeleteSearchRecord(search?._id)}
                    ></i>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500 text-sm">No recent searches found.</p>
            )}
          </div>

          {/* Saved Searches */}
          <div>
            <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
              <span className="mr-2">📌</span> Saved Searches
            </h2>
            {savedSearchData.length > 0 ? (
              <ul className="space-y-4">
                {savedSearchData.map((search: any) => (
                  <li
                    key={search?._id}
                    className="text-gray-800 cursor-pointer"
                  >
                    <span
                      onClick={() => handleSearchClick(search?.searchPrams)}
                      className="text-blue-500 font-semibold hover:underline"
                    >
                      {search?.search_name}
                    </span>
                    <span className="ml-4 text-gray-500 text-sm">
                      - Saved on {moment(search.createdAt).format("MM/DD/YYYY")}
                    </span>
                    <i
                      className="fa-regular fa-trash-can cursor-pointer text-blue-600 hover:text-blue-700 ml-4"
                      onClick={() => handleDeleteSearchRecord(search?._id)}
                    ></i>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500 text-sm">
                There is no saved search right now
              </p>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      {isLoading && <LoadingSpinner />}
    </React.Fragment>
  );
}
