import React, { useState } from "react";
import defaultPlaceholder from "../common/assets/images/user-placeholder.png";
import { useNavigate } from "react-router-dom";
import { VIEWS } from "../routes/routes";
import { saveAs } from "file-saver";

interface ProfileCardProps {
  candidateName: string;
  currentEmployment: {
    companyName?: string;
    designation?: string;
  };
  previousExperience: {
    companyName?: string;
    designation?: string;
  }[];
  education: {
    degree?: string;
    institution?: string;
    year?: number;
  }[];
  location: string;
  keySkills: string[];
  profileSummary: string;
  phoneNumber: string; // Optional phone number if already available
  totalExperience: number;
  file?: string;
  email?: string;
  updatedAt?: string;
  resumeId: string;
  salary?: string | number;
  keywords?: string[];
}

export default function ProfileCard({
  candidateName,
  currentEmployment,
  previousExperience,
  education,
  location,
  keySkills,
  profileSummary,
  phoneNumber,
  totalExperience,
  file,
  email,
  updatedAt,
  resumeId,
  salary,
  keywords,
}: ProfileCardProps) {
  // Initialize readableUpdatedAtDate variable outside the conditional block
  let readableUpdatedAtDate = "N/A";

  // Check if updatedAt exists and is valid, then convert it to a readable format
  if (updatedAt) {
    const updatedAtDate = new Date(updatedAt);
    readableUpdatedAtDate = updatedAtDate.toLocaleString();
  } else {
    console.log("Updated At is undefined");
  }

  const navigate = useNavigate();

  // Highlight matching keywords
  const highlightText = (text: string | undefined): string => {
    if (!text || !keywords || keywords.length === 0) return text || "";

    // Escape special characters in keywords and create a regex pattern
    const escapedKeywords = keywords.map((k) =>
      k.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
    );
    const regex = new RegExp(`(${escapedKeywords.join("|")})`, "gi");

    return text.replace(
      regex,
      (match) => `<span class="highlight">${match}</span>`
    );
  };

  const handleDownload = () => {
    if (!file) {
      console.error("No file available for download.");
      return;
    }
    const resumeUrl = `${process.env.REACT_APP_S3_BUCKET_URL}/${file}`;
    const fileName = `Vikalp_${candidateName.replace(/\s+/g, "_")}.pdf`;
    saveAs(resumeUrl, fileName);
  };

  return (
    <div className="bg-slate-50 rounded-lg w-full h-auto flex flex-col lg:flex-row items-center justify-center shadow-md border-2 border-slate-200 my-12">
      <div className="flex flex-col p-6 lg:p-12 2xl:border-r-2 border-slate-200 w-full 2xl:w-2/3">
        <h1 className="text-slate-800 font-semibold text-xl flex-1">
          {/* <a
            className="mt-5 text-blue-800 text-md font-semibold underline"
            href={`${process.env.REACT_APP_S3_BUCKET_URL}/${file}`}
            target="_blank"
            rel="noreferrer"
            dangerouslySetInnerHTML={{
              __html: highlightText(candidateName || ""),
            }}
          /> */}
          <span
            className="mt-5 text-blue-800 text-md font-semibold underline cursor-pointer"
            onClick={handleDownload}
          >
            {candidateName}
          </span>
          <i
            className="fa-solid fa-pen ml-5 text-secondary-dark cursor-pointer"
            onClick={() =>
              navigate(`${VIEWS.USER_RESUME_UPDATE}?id=${resumeId}`)
            }
          ></i>
        </h1>
        <div className="flex-1 text-slate-400 mt-4">
          <span className="text-xs">
            <i className="fa-solid fa-briefcase" /> {`${totalExperience} y`}
          </span>
          <span className="text-xs ml-8">
            <i className="fa-solid fa-wallet" />{" "}
            {salary ? salary.toLocaleString() : "0"}
          </span>
          <span className="text-xs ml-8">
            <i className="fa-solid fa-location-dot" /> {location}
          </span>
        </div>
        <div className="overflow-x-auto mt-4">
          <table className="table-auto w-full text-sm">
            <tbody>
              <tr className="h-8">
                <td className="font-semibold w-32">Current</td>
                <td>
                  {currentEmployment?.designation ||
                  currentEmployment?.companyName
                    ? `${currentEmployment?.designation ?? "N/A"} at ${
                        currentEmployment?.companyName ?? "N/A"
                      }`
                    : "N/A"}
                </td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Previous</td>
                <td>
                  {previousExperience[0]?.designation ||
                  previousExperience[0]?.companyName
                    ? `${previousExperience[0]?.designation ?? "N/A"} at ${
                        previousExperience[0]?.companyName ?? "N/A"
                      }`
                    : "N/A"}
                </td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Education</td>
                <td>
                  {education[0]?.degree} {education[0]?.institution} (
                  {education[0]?.year})
                </td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Pref. location</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: highlightText(location || ""),
                  }}
                ></td>
              </tr>
              <tr className="h-8">
                <td className="font-semibold">Key Skills</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: highlightText(keySkills.join(" | ")) || "",
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
          <p className="mt-10 text-xs text-slate-400">
            Last updated : {readableUpdatedAtDate}
          </p>
        </div>
      </div>

      {/* Right Section (Profile image and contact) */}
      <div className="w-full 2xl:w-1/3 flex flex-col justify-center items-center p-6 lg:p-10">
        <img
          src={defaultPlaceholder}
          className="w-32 h-32 rounded-full shadow-lg"
          alt=""
        />
        <p className="font-semibold text-xs text-center mt-5">
          {profileSummary}
        </p>
        <a
          href={`tel:${phoneNumber}`}
          className="w-full h-10 border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-sm font-semibold py-2 px-4 rounded-lg transition duration-300 mt-5 mb-2 flex justify-center items-center"
        >
          <i className="fa-solid fa-phone mr-2"></i> {phoneNumber}
        </a>
        <a
          href={`mailto:${email}`}
          className="w-full h-10 border-2 border-blue-800 hover:border-blue-700 text-blue-800 text-sm font-semibold py-2 px-4 rounded-lg transition duration-300 mt-2 mb-2 flex justify-center items-center"
        >
          <i className="fa-solid fa-envelope mr-2"></i> {email}
        </a>
      </div>
    </div>
  );
}
