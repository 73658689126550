import React, { useEffect, useState } from "react";
import InputField from "../../input-field.component";
import Button from "../../button.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../loading-spinner.component";
import useToken from "../../../hooks/useToken";
import { updateUser } from "../../../services/user-api.service";
import { useAuth } from "../../../common/providers/user.provider";

export default function EditGeneralDetailsForm(props: {}) {
  const { user } = useAuth();
  const { token, setToken, clearToken } = useToken();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName || "");
      setLastName(user.lastName || "");
      setEmail(user.email || "");
    }
  }, [user]);

  const handleEditGeneralDetails = async () => {
    if (firstName === "") {
      toast.error("Please enter first name");
    } else if (lastName === "") {
      toast.error("Please enter last name");
    } else {
      try {
        setIsLoading(true);
        const response = await updateUser(
          token,
          user?.id || "",
          firstName,
          lastName
        );
        if (response.success) {
          setIsLoading(false);
          window.location.reload();
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="w-full h-fit bg-slate-100 p-5 lg:p-10 rounded-xl my-10">
        <h1 className="text-xl font-semibold mt-5 mb-10 text-slate-600">
          General Details
        </h1>
        <span className="w-full mt-5 mr-5">
          <InputField
            icon="fa-solid fa-envelope"
            type="email"
            label="Account email *"
            placeholder="You can not change the account email"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            disabled={true}
          />
        </span>
        <div className="flex flex-col md:flex-row mb-5">
          <span className="w-full mr-5 ">
            <InputField
              icon="fa-solid fa-user-large"
              type="text"
              label="First name *"
              placeholder="Enter your first name"
              value={firstName}
              onChange={(e: any) => setFirstName(e.target.value)}
            />
          </span>
          <span className="w-full">
            <InputField
              icon="fa-solid fa-user-large"
              type="text"
              label="Last name *"
              placeholder="Enter your last name"
              value={lastName}
              onChange={(e: any) => setLastName(e.target.value)}
            />
          </span>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/5 mt-5">
          <Button
            name={"Save Changes"}
            handleAction={handleEditGeneralDetails}
          />
        </div>
      </div>
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
